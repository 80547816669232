import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import H1 from '@rotaready/frecl/build/H1';
import Text from '@rotaready/frecl/build/Text';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Button from '@rotaready/frecl/build/Button';

import { normalizePost } from '../utilities/blog';

import Body from '../components/layout/Body';
import ResponsiveContainer from '../components/layout/ResponsiveContainer';
import SEO from '../components/SEO';
import PostCardContainer from '../components/blog/PostCardContainer';
import Hero from '../components/blog/Hero';
import ContentWrapper from '../components/layout/ContentWrapper';
import TextHeadingWrapper from '../components/layout/TextHeadingWrapper';

const Pagination = styled(FlexContainer)`
  justify-content: center;
`;

const PaginationButton = styled(Button)`
  margin: 0 10px;
  width: 100px;
`;

const BlogIndex = ({ data, pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  const posts = data.allWpPost.edges.map(edge => normalizePost(edge.node));

  return (
    <Body>
      <SEO
        title="Blog - Rotaready"
        description="Everything you need to know about the latest releases, updates and Rotaready news with a dash of industry insight from the people who empower your business!"
        url="blog"
      />
      <ResponsiveContainer>
        <ContentWrapper>
          <Hero>
            <TextHeadingWrapper>
              <H1 uistyle="brand160" text="Rotaready blog" />
            </TextHeadingWrapper>

            <Text size="xl" uistyle="grey" text="Everything you need to know about the latest releases, updates and Rotaready news with a dash of industry insight from the people who empower your business!" />
          </Hero>

          <PostCardContainer posts={posts} />

          <Pagination>
            {previousPagePath
            && (
              <Link to={pageContext.previousPagePath}><PaginationButton uistyle="primary" ghost text="Previous" /></Link>
            )}
            {nextPagePath
            && (
              <Link to={pageContext.nextPagePath}><PaginationButton uistyle="primary" text="Next" /></Link>
            )}
          </Pagination>
        </ContentWrapper>
      </ResponsiveContainer>
    </Body>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export default BlogIndex;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...PostSummaryFragment
        }
      }
    }
  }
`;
