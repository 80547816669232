import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { ViewportBreakpoints } from '../cssConstants';

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 40px;
  }
`;

const TextHeadingWrapper = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

TextHeadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextHeadingWrapper;
